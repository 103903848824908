import React from "react"
import { Text as RebassText } from "@rebass/emotion"

export default function Text({ ...props }) {
  return (
    <RebassText
      as="p"
      fontSize={[0, 1]}
      mb={3}
      mt={0}
      lineHeight="1.4"
      css={{ "& > a": { textDecoration: "underline" } }}
      {...props}
    />
  )
}
