import React from "react"
import { css, Global } from "@emotion/core"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Box } from "@rebass/emotion"
import { Navbar, ThemeProvider } from "../components"
import "normalize.css"

const Layout = ({ children }) => (
  <ThemeProvider>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <Box
          css={theme => css`
            padding-top: ${theme.heights.nav};
          `}
        >
          <Global
            styles={css`
              body {
                font-family: Raleway;
              }

              a {
                color: currentColor;
                text-decoration: none;
                /* for very long links */
                overflow-wrap: break-word;
                word-break: break-word;
                hyphens: auto;
              }

              a:hover,
              a:focus {
                text-decoration: none;
                color: currentColor;
              }
            `}
          />
          <Navbar siteTitle={data.site.siteMetadata.title} />
          <link
            href="https://fonts.googleapis.com/css?family=Raleway:400&display=swap"
            rel="stylesheet"
          />
          <main>{children}</main>
          <Box as="footer" bg="grey" pt="200px">
            <Box bg="black" pb="50px" />
          </Box>
        </Box>
      )}
    />
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
