import React from "react"
import { css, keyframes } from "@emotion/core"
import { Box, Flex } from "@rebass/emotion"
import { Text, Link } from "../components"
import { Menu, MenuList, MenuButton, MenuLink } from "@reach/menu-button"
import "@reach/menu-button/styles.css"
import { FaTwitter, FaInstagram, FaEnvelope, FaCaretDown } from "react-icons/fa"

const slideDownAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const regularLinks = [
  {
    title: "10 Practices",
    path: "/10practices",
  },
  {
    title: "News",
    path: "/news",
  },
  {
    title: 'Online Resources',
    path: '/onlineresources'
  }
]

const initiativeLinks = {
  title: "Initiatives",
  subNav: [
    {
      title: "Haiti",
      path: "/initiatives/haiti",
    },
    {
      title: "Egypt",
      path: "/initiatives/egypt",
    },
    {
      title: "Nepal",
      path: "/initiatives/nepal",
    },
  ],
}

const connectlinks = {
  title: "Connect",
  subNav: [
    {
      title: "Twitter",
      icon: FaTwitter,
      path: "https://twitter.com/IELcollab",
    },
    {
      title: "Email",
      icon: FaEnvelope,
      path: "mailto:ssider@wlu.ca",
    },
    {
      title: "Instagram",
      icon: FaInstagram,
      path: "https://www.instagram.com/elicollaboration/",
    },
  ],
}

const links = [].concat(regularLinks, initiativeLinks, connectlinks)

const DesktopNavLink = (link, i) => {
  return !link.subNav ? (
    <Link to={link.path}>{link.title}</Link>
  ) : (
    <Menu>
      <MenuButton
        css={css`
          background: transparent;
          border: none;
        `}
      >
        <Text color="white" fontSize="18" mb="0" style={{ cursor: "pointer" }}>
          {link.title}

          <FaCaretDown
            css={css`
              margin-left: 6px;
              position: relative;
              top: 3px;
            `}
          />
        </Text>
      </MenuButton>
      <MenuList
        className="slide-down"
        css={css`
          border-radius: 5px;
          animation: ${slideDownAnimation} 0.12s ease;
        `}
      >
        {link.subNav.map((subnavItem, j) => (
          <MenuLink as={Link} to={subnavItem.path} key={`subnav_${i + j}`}>
            {subnavItem.icon && (
              <Box
                mr="2"
                css={css`
                  display: inline-block;
                  position: relative;
                  top: 2px;
                `}
              >
                <subnavItem.icon />
              </Box>
            )}
            {subnavItem.title}
          </MenuLink>
        ))}
      </MenuList>
    </Menu>
  )
}

const MobileHamburgerMenuButton = ({ open, set }) => {
  const barStyles = `
    transition: 0.12s ${open ? `ease-in-out` : `ease-out`} transform;
  `

  return (
    <button
      onClick={() => set(!open)}
      css={theme => css`
        position: absolute;
        right: 0;
        margin: 0 ${theme.space[2]};
        cursor: pointer;
        display: block;
        background: transparent;
        border: none;

        ${theme.mq[2]} {
          display: none;
        }

        & > div {
          width: 24px;
          height: 3px;
          background-color: ${theme.colors.white};
          margin: 5px 0;
        }
      `}
    >
      <div
        css={css`
          ${barStyles};
          transform: ${open ? `rotate(45deg)` : `rotate(0)`};
          transform-origin: top left;
        `}
      />
      <div
        css={css`
          ${barStyles};
          opacity: ${open ? 0 : 1};
          transition-delay: ${!open ? `0.05s` : `0s`};
          transition-property: opacity;
        `}
      />
      <div
        css={css`
          ${barStyles};
          transform: ${open ? `rotate(-45deg)` : `rotate(0)`};
          transform-origin: bottom left;
        `}
      />
    </button>
  )
}

const Navbar = ({ siteTitle }) => {
  const [mobileNavOpen, setMobileNavState] = React.useState(false)

  return (
    <Flex
      bg="rgba(0,0,0,0.95)"
      width={1}
      alignItems="center"
      justifyContent="space-between"
      px="3"
      height="nav"
      color="white"
      css={theme => css`
        position: fixed;
        top: 0;
        height: ${theme.heights.nav};
        z-index: ${theme.z.nav};
      `}
    >
      <Box>
        <Link
          to="/"
          css={css`
            color: currentColor;
            text-decoration: none;
          `}
        >
          <Text fontSize={24} mb="0">
            {siteTitle}
          </Text>
        </Link>
      </Box>
      <MobileHamburgerMenuButton open={mobileNavOpen} set={setMobileNavState} />
      <Flex
        alignItems="center"
        ml="auto"
        css={theme => css`
          display: none;
          ${theme.mq[2]} {
            display: flex;
          }
        `}
      >
        {links.map((link, i) => (
          <Box mr={[2, 3, 4]} key={i}>
            {DesktopNavLink(link, i)}
          </Box>
        ))}

        <Link to="https://secureca.imodules.com/s/1681/giving/19/form.aspx?sid=1681&gid=2&pgid=613&dids=151&bledit=1">
          <Box
            py="2"
            px="3"
            css={theme => css`
              border: 2px solid currentColor;
              border-radius: ${theme.borderRadius[0]};
            `}
          >
            DONATE
          </Box>
        </Link>
      </Flex>
      <Flex
        flexDirection="column"
        px="3"
        py="2"
        bg="black"
        width={[1]}
        css={theme => css`
          position: absolute;
          left: ${mobileNavOpen ? `0%` : `100%`};
          text-align: center;
          top: calc(${parseInt(theme.heights.nav) - 1}px);
          height: 100vh;
          max-height: calc(100vh - ${parseInt(theme.heights.nav) - 1}px);
          transition: 0.2s ease-in-out left;
          overflow: scroll;

          & > * {
            margin-bottom: ${theme.space[2]};
          }
        `}
      >
        <Flex
          flexDirection="column"
          pt="3"
          css={css`
            border-bottom: 2px solid white;
            border-top: 2px solid white;
          `}
        >
          {regularLinks.map((link, i) => (
            <Link
              to={link.path}
              key={i}
              css={theme => css`
                margin-bottom: ${theme.space[3]};
                font-size: 24px;
              `}
            >
              {link.title}
            </Link>
          ))}
        </Flex>
        <Flex
          flexDirection="column"
          pt="3"
          css={css`
            border-bottom: 2px solid white;
          `}
        >
          <Text fontSize="18px">{initiativeLinks.title}</Text>
          {initiativeLinks.subNav.map((link, i) => (
            <Link
              to={link.path}
              key={i}
              css={theme => css`
                margin-bottom: ${theme.space[3]};
                font-size: 24px;
              `}
            >
              {link.title}
            </Link>
          ))}
        </Flex>
        <Flex
          flexDirection="column"
          pt="3"
          css={css`
            border-bottom: 2px solid white;
          `}
        >
          <Text fontSize="18px">{connectlinks.title}</Text>
          <Flex justifyContent="center">
            {connectlinks.subNav.map((link, i) => (
              <Link
                to={link.path}
                key={i}
                css={theme => css`
                  margin: 0 ${theme.space[3]};
                  margin-bottom: ${theme.space[3]};
                  font-size: 24px;
                `}
              >
                <link.icon />
              </Link>
            ))}
          </Flex>
        </Flex>
        <Link to="https://secureca.imodules.com/s/1681/giving/19/form.aspx?sid=1681&gid=2&pgid=613&dids=151&bledit=1">
          <Box
            mt="3"
            mx="auto"
            py="2"
            px="3"
            css={theme => css`
              border: 2px solid currentColor;
              border-radius: ${theme.borderRadius[0]};
            `}
          >
            DONATE
          </Box>
        </Link>
      </Flex>
    </Flex>
  )
}

export default Navbar
