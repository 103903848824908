import React from "react"
import { css } from "@emotion/core"
import { Box } from "@rebass/emotion"

export default function Container({ ...props }) {
  return (
    <Box
      mx="auto"
      my="0"
      p="3"
      width={[1, 540, "720px", "960px", "1140px"]}
      css={theme => css`
        max-width: ${theme.widths.max};
      `}
      {...props}
    />
  )
}
