import React from "react"
import { Heading as RebassHeading } from "@rebass/emotion"

export default function Heading({ h3, ...props }) {
  return (
    <RebassHeading
      mb={3}
      fontWeight="normal"
      as={h3 ? "h3" : "h2"}
      fontSize={h3 ? ["36px", "36px", "48px"] : ["40px", "40px", "60px"]}
      {...props}
    />
  )
}
