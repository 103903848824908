import React from "react"
import { css } from "@emotion/core"
import { Heading } from "../components"
import cornerBlackDecorator from "../images/decorators/full.png"
import horizontalDecorator from "../images/decorators/horizontal.png"
import cornerWhiteDecorator from "../images/decorators/hero.png"
import { Box, Image } from "@rebass/emotion"

const sharedStyles = `
  position: absolute;
  pointer-events: none;
  max-width: 229px
  user-select: none;
`

export default function DecoratedHeading({
  type,
  decoratorAlignment,
  ...props
}) {
  const DefaultLeftAlignedDecorator = () => (
    <Image
      css={css`
        ${sharedStyles}
      `}
      src={horizontalDecorator}
    />
  )

  const DefaultDecorator = () => (
    <Image
      css={css`
        ${sharedStyles}
        left: 50%;
        transform: translateX(-50%);
      `}
      src={horizontalDecorator}
    />
  )

  const CornerWhiteDecorator = () => (
    <Image
      css={css`
        ${sharedStyles}
        bottom: -10px;
        left: -10px;
      `}
      width="229px"
      src={cornerWhiteDecorator}
      alt=""
    />
  )

  const CornetBlackDecorator = () => (
    <Image
      css={css`
        ${sharedStyles}
        bottom: -2px;
        left: -10px;
      `}
      src={cornerBlackDecorator}
      alt=""
    />
  )

  let decoratorType

  switch (type) {
    case "cornerWhite":
      decoratorType = <CornerWhiteDecorator />
      break
    case "cornerBlack":
      decoratorType = <CornetBlackDecorator />
      break
    case "left":
      decoratorType = <DefaultLeftAlignedDecorator />
      break
    default:
      decoratorType = <DefaultDecorator />
  }

  return (
    <Box
      css={css`
        position: relative;
        display: inline-block;
      `}
      pl={type && 3}
      mb={4}
    >
      <Heading mb="1" {...props} />
      {decoratorType}
    </Box>
  )
}
