import React from "react"
import { ThemeProvider as TP } from "emotion-theming"

const grey = "#F2F3F6"
const white = "#fff"
const black = "#0A0A13"
const breakpoints = [576, 768, 992, 1200]

const theme = {
  widths: {
    max: `1200px`,
  },
  heights: {
    nav: `50px`,
  },
  fontSizes: [18, 24, 32, 48, 60],
  colors: {
    grey,
    white,
    black,
  },
  space: [0, "4px", "8px", "16px", "32px", "64px", "128px", "256px"],
  z: {
    nav: 10,
  },
  borderRadius: [`5px`, `10px`],
  breakpoints: breakpoints.map(point => `${point}px`),
  mq: breakpoints.map(bp => `@media (min-width: ${bp}px)`),
  buttons: {
    primary: {
      color: "#fff",
      backgroundColor: black,
    },
    outline: {
      color: black,
      backgroundColor: "transparent",
      boxShadow: "inset 0 0 2px",
    },
  },
}

export default function ThemeProvider({ dark, ...props }) {
  return <TP theme={theme} {...props} />
}
