import React from "react"
import { css } from "@emotion/core"
import { Flex } from "@rebass/emotion"
import GasbyBackgroundImage from "gatsby-background-image"

export default function Hero({ fluid, ...props }) {
  return (
    <GasbyBackgroundImage
      fluid={fluid}
      css={theme => css`
        margin-bottom: ${theme.space[6]};
      `}
    >
      <Flex
        color="white"
        alignItems="flex-end"
        pb="5"
        px="3"
        css={css`
          height: 480px;
          min-height: 480px;
        `}
        {...props}
      />
    </GasbyBackgroundImage>
  )
}
